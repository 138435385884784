import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import videoIcon from "../../svg/video-icon-white.png";
import greenVideoIcon from "../../svg/video_selected.png";
import downloadIcon from "../../svg/download-icon-white.png";
import selectedRectangle from "../../svg/selected_rectangle.png";
import greenCheckMark from "../../svg/green_checkmark.png";

const FileItem = (props) => {

    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const InnerComponent = React.memo(({ data }) => {
        return <div>{data}</div>;
    });
    const endPointUrl = "https://learnstem-backend.eduproject.eu";


    useEffect(()=>{
        setIsSelected(props.isSelected);
    },[props.isSelected]);
    function selectFile(){
        props.select(props.id);
    }

    function addItemToSeen(){
        props.addItemToSeen(props.id);
    }

    async function downloadFile() {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.status === 200) {
                const content = xhr.response;
                const url = window.URL.createObjectURL(content);

                const a = document.createElement('a');
                a.style.display = 'none';
                document.body.appendChild(a);

                a.href = url;
                a.download = props.name.endsWith('.docx') ? props.name : `${props.name}.docx`;

                a.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                console.error(`Failed to load the Word file. Status: ${xhr.status}`);
            }
        };

        xhr.open('GET', props.path);
        xhr.send();
    }


    return (
        <>
            {isMobile && (<div key={props.index} style={{marginLeft:0,position: 'relative' }} onClick={selectFile}>
                <div style={{
                    position: 'relative',
                    backgroundColor: 'rgba(29, 62, 77, 0.9)',
                    borderRadius: '30px',
                    padding: '2%',
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:"space-between"
                }}>
                    {isSelected && (
                        <div style={{
                            float: 'left',
                            left: 0,
                            marginLeft: '0.02%',
                            height: '100%',
                            position: 'absolute',
                            paddingTop: '2.5%',
                            paddingBottom: '2.5%'
                        }}>
                            <img src={selectedRectangle} />
                        </div>
                    )}
                    <div style={{ width: '10%', height: '100%', float: 'left', padding: '1%', position: 'relative' }}>
                        <div style={{ float: 'right', width: '90%' }}>
                            <img style={{ width: '100%', height: 'auto', position: 'relative' }} src={isSelected ? greenVideoIcon : videoIcon} />
                        </div>
                    </div>
                    <div style={{ width: '80%', float: 'left', position: 'relative',  maxWidth: '70%' }}>
                        <p style={{ fontSize: '1rem', marginBottom: 0, color: 'rgba(255, 255, 255, 1)', textAlign: 'left' }}>{props.name}</p>
                    </div>
                    <div style={{ width: '10%', float: 'right', position: 'relative', marginRight: 0, height: 'fit-content' }}>
                        {props.type !==  "h5p" && props.material?.seen ===  false &&  (
                            <div>
                                <img src={downloadIcon} style={{ width: '30px', height: '30px' }} />
                            </div>
                        )}
                        { props.material?.seen ===  true && (
                            <div>
                                <img src={greenCheckMark} style={{ width: '30px', height: 'auto' }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>)}
            {!isMobile && (
                <div key={props.index} style={{margin: '1.5rem', marginLeft:0,position: 'relative' }} onClick={selectFile}>
                    <div style={{
                        position: 'relative',
                        backgroundColor: 'rgba(29, 62, 77, 0.9)',
                        borderRadius: '30px',
                        padding: '2%',
                        width: '100%',
                        height: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:"space-between"
                    }}>
                        {isSelected && (
                            <div style={{
                                float: 'left',
                                left: 0,
                                marginLeft: '0.02%',
                                height: '100%',
                                position: 'absolute',
                                paddingTop: '2.5%',
                                paddingBottom: '2.5%'
                            }}>
                                <img src={selectedRectangle} />
                            </div>
                        )}
                        <div style={{ width: '10%', height: '100%', float: 'left', padding: '1%', position: 'relative' }}>
                            <div style={{ float: 'right', width: '90%' }}>
                                <img style={{ width: '100%', height: 'auto', position: 'relative' }} src={isSelected ? greenVideoIcon : videoIcon} />
                            </div>
                        </div>
                        <div style={{ width: '80%', float: 'left', position: 'relative',  maxWidth: '70%' }}>
                            <p style={{ fontSize: '1rem', marginBottom: 0, color: 'rgba(255, 255, 255, 1)', textAlign: 'left' }}>{props.name}</p>
                        </div>
                        <div style={{ width: '10%', float: 'right', position: 'relative', marginRight: 0, height: 'fit-content' }}>
                            {props.type !==  "h5p" && props.material?.seen ===  false &&  (
                                <div>
                                    <img src={downloadIcon} style={{ width: '30px', height: '30px' }} />
                                </div>
                            )}
                            { props.material?.seen ===  true && (
                                <div>
                                    <img src={greenCheckMark} style={{ width: '30px', height: 'auto' }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}

export default FileItem;
