import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants";
import { Dropdown } from "semantic-ui-react";
import "../../css/dropdown.css"

const Language = () => {
  const [t, i18n] = useTranslation("global");

  const onChangeLang = (event, data) => {
    i18n.changeLanguage(data.value);
  };


  return (
      <div className="dropdown">
          <Dropdown
              inline
              options={LANGUAGES}
              defaultValue={i18n.language}
              onChange={onChangeLang}
          />
      </div>

  );
};

export default Language;
