import "./App.css";
import MainView from "./views/MainView";
import { Suspense } from "react";
import axios from "axios";

function App() {
    const endPointUrl = "https://learnstem-backend.eduproject.eu";

    axios.post(endPointUrl + '/api/add-visitor')
        .then(response => {
        })
        .catch(error => {
        });

  return (
    <Suspense className="App container mx-auto" fallback="loading">
      <MainView />
    </Suspense>
  );
}

export default App;
