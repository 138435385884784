import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import arrow from "../../svg/blueArrow.png";
import videoIcon from "../../svg/video-icon-white.png";
import powerPointIcon from "../../svg/PPT-ICON-PNG.png";
import wordIcon from "../../svg/word.png";
import pdfIcon from "../../svg/pdfs-512.webp";

const InnerSearchItem = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const endPointUrl = "https://learnstem-backend.eduproject.eu";
    const [selectedImage, setSelectedImage] = useState(setIcon());

    function setId(){
        props.setId(props.material.id);
    }

    function setIcon(){
        if (props.files[0].type === 'word'){
            return wordIcon;
        }else if(props.files[0].type === 'pdf'){
            return pdfIcon;
        }else if(props.files[0].type === 'powerpoint'){
            return powerPointIcon;
        }else if(props.files[0].type === 'video'){
            return videoIcon;
        }
    }


    return (
        <>
            {isMobile && (
                <>
                    <div style={{ position: "relative", display: "flex", flexDirection: "row", backgroundColor: "white", borderRadius: "25px", padding: "2%", width: "100%", height: "100%" }}>
                        <div key={props.key} style={{ position: "relative", fontFamily: "Poppins, sans-serif", width: "100%", height: "100%" }}>
                            <a onClick={setId}>
                                <div style={{ position: "relative", fontFamily: "Poppins, sans-serif", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80%", marginRight: "5%", marginLeft: "5%" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <b><p style={{ fontSize: "2vh", marginBottom: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.title}</p></b>
                                            <p style={{ fontSize: "1.5vh", marginTop: 0, paddingTop: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.description}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                                        <img src={arrow} style={{ width: "5vw", height: "auto" }} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </>




            )
            }
            {!isMobile && (
                <>
                    <div style={{ position: "relative", display: "flex", flexDirection: "row", backgroundColor: "white", borderRadius: "50px", padding: "2%", width: "100%", height: "100%" }}>
                        <div key={props.key} style={{ position: "relative", fontFamily: "Poppins, sans-serif", width: "100%", height: "100%" }}>
                            <a onClick={setId}>
                                <div style={{ position: "relative", fontFamily: "Poppins, sans-serif", display: "flex", alignItems: "center"}}>
                                    <div style={{width:"10%", float:"left", position:"relative"}}>
                                        <img style={{width:"9vh", borderRadius:"50px", position:"relative"}} src={selectedImage}/>
                                    </div>
                                    <div style={{ width: "70%", marginRight: "5%", marginLeft: "5%" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <b><p style={{ fontSize: "2vh", marginBottom: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.title}</p></b>
                                            <p style={{ fontSize: "1.5vh", marginTop: 0, paddingTop: 0, color: "rgba(29, 62, 77,0.9)", textAlign: "left" }}>{props.material.description}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                                        <img src={arrow} style={{ width: "1vw", height: "auto" }} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default InnerSearchItem;
