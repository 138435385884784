import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import stemLogo from "../../svg/stemLogo.jpg";
import erasmus from "../../svg/erasmus-logo.png";
import "../../css/home-page-users.css";
import "../../css/menu.css"
import { Link } from "react-router-dom";
import Language from "./Language";
import MobileMenu from "./MobileMenu";

const Menu = (props) => {
  const [t, i18n] = useTranslation("global");
  const [showMenu, setShowMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 970);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(props.currentPage)
  }, [props.currentPage]);

  useEffect(()=>{
    if(windowWidth > 1022){
      setShowMenu(false)
    }

  })

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };

  let backgroundHeader =
    "bg-black/30 fixed top-0 left-0 right-0 z-20";
  if (!props.changeVersionHandler) {
    backgroundHeader =
      "bg-white border-b-2 border-transparent fixed top-0 left-0 right-0 z-20";
  }


  let colors =
    "text-white hover:text-white text-1xl font-bold underline-offset-0 justify-self-center self-center h-32 grid content-center";

  let colors_selected =
      "text-white bg-black hover:text-white text-1xl font-bold underline-offset-0 justify-self-center self-center h-32 grid content-center";

  if (!props.changeVersionHandler) {
    colors =
      "text-1xl text-black hover:text-black font-bold underline-offset-0 justify-self-center self-center";
  }
  return (
      <>
        {isMobile && (<MobileMenu/>)}
        {!isMobile && (
            <div>
              <div className={backgroundHeader} style={{background:"rgba(1, 37, 55,0.4)"}}>
                <nav style={{width:"100%", display:"flex", justifyContent:"space-around"}}>

                  <div style={{float:"left", width:"10%"}}>
                    <Link
                        href="#learningUnits"
                        to={"/"}
                        className="w-32 justify-self-center sticky-top"
                        onClick={() => handleSetCurrentPage("learning-units")}
                    >
                      <img className="lg:h-32 mb-4 stem-logo " src={stemLogo} alt="logo" />
                    </Link>

                  </div>
                  <div style={{float:"right", width:"70%", fontSize:"2.5vh"}}>
                    <div style={{display:"flex", height:"100%", justifyContent:"flex-end"}}>
                      <Link
                          className={currentPage === 'learning-units' ? "menu-button-selected" : "menu-button"}
                          style={{ fontFamily: "Poppins, sans-serif", paddingLeft:"2%", paddingRight:"2%" }}
                          href="#learningUnits"
                          to={"/"}
                          onClick={() => handleSetCurrentPage("learning-units")}
                      >
                        {t("menu.learning_units")}
                      </Link>
                      <Link
                          className={currentPage === 'about' ? "menu-button-selected" : "menu-button"}
                          style={{ fontFamily: "Poppins, sans-serif", paddingLeft:"2%", paddingRight:"2%" }}
                          href="#about"
                          to={"/about"}
                          onClick={() => handleSetCurrentPage("about")}
                      >
                        {t("menu.about")}
                      </Link>
                      <Link
                          className={currentPage === 'contacts' ? "menu-button-selected" : "menu-button"}
                          style={{ fontFamily: "Poppins, sans-serif", paddingLeft:"2%", paddingRight:"2%" }}
                          href="#contacts"
                          to={"/contacts"}
                          onClick={() => handleSetCurrentPage("contacts")}
                      >
                        {t("menu.contacts")}
                      </Link>
                      <div style={{display:"flex", alignItems: "center", marginLeft:"5%", columnGap:"5%"}}>
                        <div style={{position:"relative",display:"flex" }}>
                          {/*<CustomToggleButton/>*/}
                          <a
                              className={colors}
                              style={{ fontFamily: "Poppins, sans-serif",
                                borderRadius:"10vh",
                                background:"rgb(6, 36, 51)",
                                height:"100%",
                                borderColor:"white",
                                borderWidth:"0.3vh",
                                padding:"1vh",
                                width:"auto",
                                textAlign:"center",
                                lineHeight: "2vh"}}
                              href="https://learnstem-backend.eduproject.eu/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            {t("menu.dashboard")}
                          </a>
                        </div>

                        <div style={{display:"flex", flex:1}}>
                          <div>
                            <Language />
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </nav>
              </div>
            </div>

        )}
      </>
  );
};
// createRoot.render(<Menu />, document.getElementById("content"));

export default Menu;
