import React, {useEffect, useState} from "react";
import "../../css/stem-input.css"
import "../../css/home-page-users.css"
import backButton from "../../svg/backButton.png"
import FileItem from "./FileItem";
import DecorativeBar from "./DecorativeBar";
import {useTranslation} from "react-i18next";
import greenDownloadIcon from "../../svg/download-icon-green.png";



const Files = (props) => {
    const [t, i18n] = useTranslation("global");
    const [selectedMedia, setSelectedMedia] = useState("");
    const [selectedTitle, setSelectedTitle] = useState(props.materials[props.selectedId].title);
    const [selectedDescription, setSelectedDescription] = useState(props.materials[props.selectedId].description);
    const [selectedName, setSelectedName] = useState(props.materials[props.selectedId].name);
    const [selectedImage, setSelectedImage] = useState(props.materials[props.selectedId].files[0].path);
    const [selectedId, setSelectedId] = useState(props.selectedId);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileHP5, setSelectedFileHP5] = useState(props.materials[props.selectedId].files[0].h5p);
    const [files, setFiles] = useState(props.materials);
    const endPointUrl = "https://learnstem-backend.eduproject.eu";
    const [numberOfItemsSeen, setNumberOfItemsSeen] = useState(0);
    const [unitData, setUnitData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // Function to handle saving data to localStorage
    const saveDataToLocalStorage = (updatedItems) => {
        localStorage.setItem(props.unitId, JSON.stringify(updatedItems));
    };



    useEffect(() => {
        const savedData = localStorage.getItem(props.unitId);
        if (savedData) {
            setUnitData(JSON.parse(savedData));
            setNumberOfItemsSeen(JSON.parse(savedData).length);
            console.log(JSON.parse(savedData));
            Object.keys(JSON.parse(savedData)).forEach(function(index) {
                let files_temp = files;
                if (files_temp[JSON.parse(savedData)[index]]) {
                    files_temp[JSON.parse(savedData)[index]].seen = true;
                    setFiles(files_temp);
                }
            });

        }
    }, []);


    const setGoBack = () => {
        if (props.isSearching){
            props.setIsSearching(false);
            props.setIsMainViewActive(true);
        }else{
            props.setIsSearching(true);
        }
    }

    const addItemToSeen = (id) => {
        if (!unitData.includes(id)) {
            const updatedItems = [...unitData, id];
            setUnitData(updatedItems);
            saveDataToLocalStorage(updatedItems);
            setNumberOfItemsSeen(updatedItems.length);
        }
        let files_temp = files;
        if (files_temp[id]) {
            files_temp[id].seen = true;
            setFiles(files_temp);
        }
    };


    function select(id){
        Object.entries(files).forEach((file)=>{
            file.isSelected = false;
        });
        files[id].isSelected = true;
        setSelectedId(id);
        setSelectedTitle(files[id].title);
        setSelectedName(files[id].name);
        setSelectedMedia(files[id].media);
        setSelectedDescription(files[id].description);
        setSelectedImage(files[id].files[0].path ?? '');
        setSelectedFile(files[id].file);
        setSelectedFileHP5(files[id].hp5);
        if (files[id].files[0].path == null){
            addItemToSeen(id);
        }
    }

    const FilePreview = () => {
        let id = selectedId;
        const fileType = files[id].files[0].type;

        switch (fileType) {
            case 'image':
                return <img src={endPointUrl + files[id].files[0].path} alt="Selected Image" />;
            case 'pdf':
                // Render a document preview component
                return <NoPreview file={files[id].files[0]}  type={'pdf'}/>;
            case 'word':
                // Render a document preview component
                return <NoPreview file={files[id].files[0]} type={'docx'}/>;
            case 'video':
                // Render a video preview component
                return <VideoPreview file={files[id].files[0].h5p}/>;
            case 'powerpoint':
                return <NoPreview file={files[id].files[0]} type={'pptx'}/>;
            case 'h5p':
                return  <H5PPreview file={files[id].files[0].h5p}/>;
            default:
                // Render a generic preview for unknown file types
                return <div>{t('files.no_preview')}</div>;
        }
    };

    const getFileType = (fileName) => {
        if (fileName){const extension = fileName.split('.').pop().toLowerCase();
            if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                return 'image';
            } else if (['pdf', 'doc', 'docx'].includes(extension)) {
                return 'document';
            } else if (['mp4', 'avi', 'mkv'].includes(extension)) {
                return 'video';
            }else if (['pptx'].includes(extension)) {
                return 'powerpoint';
            }
            else {
                return 'unknown';
            }}
    };

    const H5PPreview = ({ file }) => {
        // Render a document preview component based on the file type
        return (
            <>
                <div style={{
                    fontSize: "3vh",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                    hyphens: "auto",
                    fontWeight: "bold"

                }}>
                    {selectedTitle}
                </div>
                <div className="mt-5" style={{ fontSize: "2vh", textAlign: "left",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                    hyphens: "auto"}}>
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div
                            className="mt-5"
                            style={{
                                width: "100%",
                                position: "relative",
                                height: "40%", // Change fixed height to auto for responsiveness
                                display: "flex",
                                flexDirection: "column", // Stack child elements vertically
                                alignItems: "center", // Center child elements horizontally

                            }}
                        >
                            <div
                                style={{
                                    border: "2px solid white",
                                    padding: "5px",
                                    width: "100%",
                                    overflow: "hidden", // Ensure content doesn't overflow
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                                    hyphens: "auto",
                                }}
                            >
                                <div style={{height: "100%", position: "relative", overflow: "auto", maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}} dangerouslySetInnerHTML={{ __html: file }} />
                            </div>
                        </div>
                        <div
                            className="mt-5"
                            style={{ fontSize:"1.5vh", textAlign: "left", position: "relative"}}
                        >
                            {selectedName}
                        </div>
                    </>
                )}

            </>
        )
    };

    //Embed Youtube
    const VideoPreview = ({ file }) => {
        // Render a document preview component based on the file type
        return (
            <>
                <div style={{
                    fontSize: "3vh",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                    hyphens: "auto",
                    fontWeight: "bold"

                }}>
                    {selectedTitle}
                </div>
                <div className="mt-5" style={{ fontSize: "2vh", textAlign: "left",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                    hyphens: "auto"}}>
                    {selectedDescription}
                </div>
                {selectedName && (
                    <>
                        <div
                            className="mt-5"
                            style={{
                                width: "100%",
                                position: "relative",
                                height: "100%", // Change fixed height to auto for responsiveness
                                display: "flex",
                                flexDirection: "column", // Stack child elements vertically
                                alignItems: "center", // Center child elements horizontally

                            }}
                        >
                            <div
                                style={{
                                    border: "2px solid white",
                                    padding: "5px",
                                    width: "100%",
                                    overflow: "hidden", // Ensure content doesn't overflow
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",  // Allow long words to break into multiple lines
                                    overflowWrap: "break-word",  // Ensure that words break at the end of the line
                                    hyphens: "auto",
                                }}
                            >
                                <div style={{height: "auto", display:"flex", justifyContent:"center", width:"auto",position: "relative", overflow: "auto",}} dangerouslySetInnerHTML={{ __html: file }} />
                            </div>
                        </div>
                        <div
                            className="mt-5"
                            style={{ fontSize:"1.5vh", textAlign: "left", position: "relative"}}
                        >
                            {selectedName}
                        </div>
                    </>
                )}

            </>
        )
    };

    const NoPreview = ({ file, type }) => {
        // Render a document preview component based on the file type
        return (
            <>
                {isMobile && (
                    <>
                        <div style={{
                            fontSize: "3vh",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                            hyphens: "auto",
                            fontWeight: "bold"

                        }}>
                            {selectedTitle}
                        </div>
                        <div className="mt-5" style={{ fontSize: "2vh", textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                            hyphens: "auto"}}>
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <>
                                <div
                                    className="mt-5"
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                        height: "40%", // Change fixed height to auto for responsiveness
                                        display: "flex",
                                        flexDirection: "column", // Stack child elements vertically
                                        alignItems: "center", // Center child elements horizontally

                                    }}
                                >
                                    <div
                                        style={{
                                            border: "2px solid white",
                                            padding: "5px",
                                            width: "100%",
                                            overflow: "hidden", // Ensure content doesn't overflow
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                                            hyphens: "auto",
                                        }}
                                    >
                                        <div style={{height: "100vh",position: "relative", display:"flex", overflow: "auto", maxWidth: "100%", maxHeight: "100%", objectFit: "contain", background:"rgba(0,0,0,0.5)", justifyContent: "center", alignItems: "center"}}>
                                            <div>
                                                <div style={{fontSize:"2vh", padding:"1vh"}}>
                                                    {t("files.no_preview")}
                                                </div>
                                                <div style={{ marginTop: "0", display: "flex", justifyContent: "center", padding: "1vh" }}>
                                                    <button className="slider-button-browse-units" style={{ paddingTop: "2.5%", paddingBottom: "2.5%", paddingLeft: "7%", paddingRight: "7%" }}>
                                                        <a
                                                            href={endPointUrl + "/api/download-file/" + file?.id}
                                                            download={selectedName}
                                                            onClick={() => addItemToSeen(selectedId)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{ color: "rgb(0, 231, 110,1)" }}
                                                        >
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flex: 1, width: "100%" }}>
                                                                <div style={{ display: "flex", marginRight: "2vw" }}> {/* Added margin to create space */}
                                                                    <p>{t("slider.download")}</p>
                                                                </div>
                                                                <div style={{ display: "flex" }}>
                                                                    <img src={greenDownloadIcon} style={{ width: '30px', height: '30px' }} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5"
                                    style={{ fontSize:"1.5vh", textAlign: "left", position: "relative"}}
                                >
                                    {selectedName}
                                </div>
                            </>
                        )}
                    </>
                )}
                {!isMobile && (
                    <>
                        <div style={{
                            fontSize: "3vh",
                            textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                            hyphens: "auto",
                            fontWeight: "bold"

                        }}>
                            {selectedTitle}
                        </div>
                        <div className="mt-5" style={{ fontSize: "2vh", textAlign: "left",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                            hyphens: "auto"}}>
                            {selectedDescription}
                        </div>
                        {selectedName && (
                            <>
                                <div
                                    className="mt-5"
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                        height: "40%", // Change fixed height to auto for responsiveness
                                        display: "flex",
                                        flexDirection: "column", // Stack child elements vertically
                                        alignItems: "center", // Center child elements horizontally

                                    }}
                                >
                                    <div
                                        style={{
                                            border: "2px solid white",
                                            padding: "5px",
                                            width: "100%",
                                            overflow: "hidden", // Ensure content doesn't overflow
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",  // Allow long words to break into multiple lines
                                            overflowWrap: "break-word",  // Ensure that words break at the end of the line
                                            hyphens: "auto",
                                        }}
                                    >
                                        <div style={{height: "100vh", position: "relative", display:"flex", overflow: "auto", maxWidth: "100%", maxHeight: "100%", objectFit: "contain", background:"rgba(0,0,0,0.5)", justifyContent: "center", alignItems: "center"}}>
                                            <div>
                                                <div style={{objectFit: "contain", fontSize:"2vw", padding:"3vh"}}>
                                                    {t("files.no_preview")}
                                                </div>
                                                <div style={{ marginTop: "0", display: "flex", justifyContent: "center", padding: "3vh" }}>
                                                    <button className="slider-button-browse-units" style={{ paddingTop: "2.5%", paddingBottom: "2.5%", paddingLeft: "7%", paddingRight: "7%" }}>
                                                        <a
                                                            href={endPointUrl + "/api/download-file/" + file?.id}
                                                            download={selectedName}
                                                            onClick={() => addItemToSeen(selectedId)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{ color: "rgb(0, 231, 110,1)" }}
                                                        >
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flex: 1, width: "100%" }}>
                                                                <div style={{ display: "flex", marginRight: "2vw" }}> {/* Added margin to create space */}
                                                                    <p>{t("slider.download")}</p>
                                                                </div>
                                                                <div style={{ display: "flex" }}>
                                                                    <img src={greenDownloadIcon} style={{ width: '30px', height: '30px' }} />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5"
                                    style={{ fontSize:"1.5vh", textAlign: "left", position: "relative"}}
                                >
                                    {selectedName}
                                </div>
                            </>
                        )}
                    </>
                )}
            </>
        )
    };

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop().toLowerCase();
    };

    return (
        <>
            {isMobile && (
                <>
                    <div style={{ width: "100%", height:"100%",position: "relative", fontFamily: "Poppins, sans-serif"}}>
                        <div
                            style={{
                                marginTop: 0,
                                display: "flex",
                                position:"relative",
                                flexDirection:"column",
                                justifyContent: "center",
                                height:"200vh",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%", // Adjusted to take up full width on smaller screens
                                    marginTop: 0,
                                    height:"50vh",
                                    position:"relative",
                                    display: "flex",
                                    paddingLeft:"3%",
                                    paddingRight:"3%",
                                    flexDirection: "column",
                                    overflowY:"auto",
                                }}
                            >
                                <FilePreview/>

                                {!selectedName && (
                                    <div
                                        className="mt-5"
                                        style={{
                                            width: "100%", // Adjusted to take up full width on smaller screens
                                            position: "relative",
                                            height: "48%",
                                            paddingLeft:"4%",
                                            display: "flex",
                                        }}
                                    >
                                        There are no items.
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    width:"90%",
                                    paddingTop:"10%",
                                    paddingLeft:"4%",
                                    height:"100%",
                                }}
                            >


                                <div className="gap-1 grid" style={{
                                    width:"100%",
                                    fontWeight: "bold",
                                    fontSize:"2vh",
                                    height:"30vh",

                                }}>
                                    <DecorativeBar numberOfItemsSeen={numberOfItemsSeen} files={files} files_number={Object.entries(files).length}/>

                                    <div className="files-scrollview gap-1 grid" style={{
                                        width:"100%",
                                        fontWeight: "bold",
                                        fontSize:"2vh",
                                        overflowY: "scroll",
                                        position: "relative",
                                        maxHeight: "100%",
                                    }}>
                                        {Object.entries(files).map(([id, material]) => (
                                            <div key={id}>
                                                <FileItem
                                                    id={material.id}
                                                    file={material.files[0]}
                                                    material={material}
                                                    index={id} // You can use id here if it's unique
                                                    isSelected={material.id === selectedId}
                                                    name={material.name}
                                                    path={material.files[0].path}
                                                    type={material.files[0].type}
                                                    select={select}
                                                    addItemToSeen={addItemToSeen}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "sticky",
                            zIndex: 999,
                            margin: 50,
                            bottom: 10,
                        }}
                    >
                        <button className="slider-button-inner-search-exit" onClick={props.exit}>
                            <img
                                src={backButton}
                                alt="Selected Image"
                                style={{ width: "100%"}}
                            />
                        </button>
                    </div>
                </>
            )}
            {!isMobile && (
                <>
                    <div style={{ width: "100%", height:"100%",position: "relative", fontFamily: "Poppins, sans-serif"}}>
                        <div
                            style={{
                                marginTop: 0,
                                display: "flex",
                                position:"relative",
                                justifyContent: "center",
                                height:"100vh",
                            }}
                        >
                            <div
                                style={{
                                    float: "left",
                                    width: "50%",
                                    marginTop: 0,
                                    position:"relative",
                                    display: "flex",
                                    paddingLeft:"3%",
                                    paddingRight:"3%",
                                    flexDirection: "column",
                                    overflowY:"auto",
                                }}
                            >
                                <FilePreview/>

                                {!selectedName && (
                                    <div
                                        className="mt-5"
                                        style={{
                                            width: "100%", // Adjusted to take up full width on smaller screens
                                            position: "relative",
                                            height: "48%",
                                            paddingLeft:"4%",
                                            display: "flex",
                                        }}
                                    >
                                        There are no items.
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    float: "right",
                                    width:"48%",
                                    paddingTop:"2%",
                                    paddingLeft:"4%",
                                    position:"flex",
                                }}
                            >


                                <div className="gap-1 grid" style={{
                                    width:"100%",
                                    fontWeight: "bold",
                                    fontSize:"2vh",
                                    height:"60vh",

                                }}>
                                    <DecorativeBar numberOfItemsSeen={numberOfItemsSeen} files={files} files_number={Object.entries(files).length}/>

                                    <div className="files-scrollview gap-1 grid" style={{
                                        width:"100%",
                                        fontWeight: "bold",
                                        fontSize:"2vh",
                                        overflowY: "scroll",
                                        position: "relative",
                                        maxHeight: "100%",
                                    }}>
                                        {Object.entries(files).map(([id, material]) => (
                                            <div key={id}>
                                                <FileItem
                                                    id={material.id}
                                                    file={material.files[0]}
                                                    material={material}
                                                    index={id} // You can use id here if it's unique
                                                    isSelected={material.id === selectedId}
                                                    name={material.name}
                                                    path={material.files[0].path}
                                                    type={material.files[0].type}
                                                    select={select}
                                                    addItemToSeen={addItemToSeen}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "sticky",
                            zIndex: 999,
                            margin: 50,
                            bottom: 10,
                            float: "left",
                        }}
                    >
                        <button className="slider-button-inner-search-exit" onClick={props.exit}>
                            <img
                                src={backButton}
                                alt="Selected Image"
                                style={{ width: "100%"}}
                            />
                        </button>
                    </div>
                </>
            )}
        </>



    );
}

export default Files;
