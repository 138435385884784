import React, { useState, useEffect } from 'react';
import SliderItem from "./SliderItem";
import axios from 'axios';
import backArrow from "../../svg/back.png";
import nextArrow from "../../svg/next.png";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import "../../css/home-page-users.css";


export default function Slider() {
    const [t, i18n] = useTranslation("global");
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isNavigationArrows, setIsNavigationArrows] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [slideContent, setSlideContent] = useState([]);
    const endPointUrl = "https://learnstem-backend.eduproject.eu";
    const numberSlides = slideContent.length;
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };


    const nextSlide = () => {
        if (currentSlide+1 !== numberSlides - 1) {
            setIsFirstSlide(false);
        } else {
            setIsFirstSlide(false);
            setIsLastSlide(true);
        }
        setCurrentSlide(currentSlide + 1);
    }

    const previousSlide = () => {
        setCurrentSlide(currentSlide - 1);
        if (currentSlide > 1) {
            setIsLastSlide(false);
        } else {
            setIsLastSlide(false);
            setIsFirstSlide(true);
        }
    }

    useEffect(() => {
        // Fetch data from API
        axios.get(endPointUrl + '/api/units/'+i18n.language)
            .then(response => {
                console.log(response);
                setSlideContent(response.data);
                setIsLoading(false);
                if (response.data.length <= 1){
                    setIsLastSlide(true);
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data from API', error);
            });
    }, [i18n.language]);

    return (
        <>
            {isMobile && (
                <>
                    {isLoading ? (<div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>):(
                        <div className="slider middle" style={{ width: '100%', height:"100%", position: "fixed", marginTop: 0, marginBottom: 0}}>
                            {isNavigationArrows && (
                                <>
                                    {!isLastSlide && (
                                        <>
                                            <div className="float-right">
                                                <button style={{ position: "absolute", right: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "30px" }} onClick={nextSlide}>
                                                    <img src={nextArrow} alt="Next Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!isFirstSlide && (
                                        <>
                                            <div className="float-left">
                                                <button style={{ position: "absolute", left: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "30px" }} onClick={previousSlide}>
                                                    <img src={backArrow} alt="Back Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="slides">
                                {slideContent.map((slide, index) => (
                                    <SliderItem key={index} index={index} data={slide} setArrows={setIsNavigationArrows} isNavigationArrows={isNavigationArrows} currentSlide={currentSlide} />
                                ))}
                            </div>
                        </div>

                    )}
                </>
            )}
            {!isMobile && (
                <>
                    {isLoading ? (<div style={{ width: '100%', height: "100%", position: "relative", marginTop: 0, marginBottom: 0 }}><Loader/></div>):(
                        <div className="slider middle" style={{ width: '100%', height:"100%", position: "fixed", marginTop: 0, marginBottom: 0}}>
                            {isNavigationArrows && (
                                <>
                                    {!isLastSlide && (
                                        <>
                                            <div className="float-right">
                                                <button style={{ position: "absolute", right: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "50px" }} onClick={nextSlide}>
                                                    <img src={nextArrow} alt="Next Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!isFirstSlide && (
                                        <>
                                            <div className="float-left">
                                                <button style={{ position: "absolute", left: "2%", top: "50%", transform: "translateY(-50%)", zIndex: "9999", maxWidth: "50px" }} onClick={previousSlide}>
                                                    <img src={backArrow} alt="Back Arrow" style={{ width: "100%" }} />
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="slides">
                                {slideContent.map((slide, index) => (
                                    <SliderItem key={index} index={index} data={slide} setArrows={setIsNavigationArrows} isNavigationArrows={isNavigationArrows} currentSlide={currentSlide} />
                                ))}
                            </div>
                        </div>

                    )}
                </>
            )}
        </>

    );
}
