import React, { useState, useEffect } from "react";
import LearningUnits from "./LearningUnits";
import Menu from "./components/Menu";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./About";
import Contacts from "./Contacts";
import Footer from "./components/Footer";


const MainView = () => {
  const [changeVersionHandler, setchangeVersionHandler] = useState(true);
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState("learning-units");
  let { pathname } = useLocation();
  let showFooter = true;


  let background = {
    height:"100vh",
    width:"100vw",
    position:"relative",
  };


  return (
    <div id="home" style={background}>
      <Menu
        changeVersionHandler={changeVersionHandler}
        ticketsShow={!showFooter}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <Routes>
        <Route path="/" element={<LearningUnits setCurrentPage={setCurrentPage}/>} />
        <Route path="/about" element={<About setCurrentPage={setCurrentPage}/>} />
        <Route path="/contacts" element={<Contacts setCurrentPage={setCurrentPage}/>} />
      </Routes>
        <Footer/>
      {/*showFooter && <Footer show={false}/>*/}
    </div>
  );
};

export default MainView;
